import * as React from "react";
import Layout from "../components/Layout";

const TermsPage = () => (
  <Layout>
    <div>
    <section className="section content doc">
        <h1>Terms and Conditions</h1>
        <p>Welcome to One a Day!</p>
        <p>These terms and conditions outline the rules and regulations for the use of One a Day.</p>
        <p>By using this app we assume you accept these terms and conditions. Do not continue to use One a Day if you do not agree to take all of the terms and conditions stated on this page.</p>
        <h1>Content Restrictions</h1>
        <p>
          One a Day app is committed to providing a safe and positive environment for all users. As such, we have strict content restrictions in place to ensure that objectionable and abusive content is not tolerated on our platform. This includes, but is not limited to, content that is hateful, discriminatory, violent, or sexually explicit. Any user found to be posting such content will be subject to immediate account suspension or termination. We also have zero-tolerance for abusive behavior towards other users. We encourage our community to report any content or users that violate our terms and conditions. By using our app, you agree to abide by these guidelines and help us create a welcoming space for all users.
        </p>
        <h1>Licence</h1>
        <p>Unless otherwise stated, the app itself, all the trademarks, copyright, database rights, and other intellectual property rights related to it, belong to Klaudia Bronowicka. All intellectual property rights are reserved. You may access this from One a Day for your own personal use subjected to restrictions set in these terms and conditions.</p>
        <p>You must not:</p>
        <ul>
        <li>Republish material from One a Day</li>
        <li>Sell, rent or sub-license material from One a Day</li>
        <li>Reproduce, duplicate or copy material from One a Day</li>
        <li>Redistribute content from One a Day</li>
        <li>Copy or modify One a Day application and/or any of its parts</li>
        <li>Attempt to extract the source code of the app</li>
        <li>Translate the app into other languages</li>
        <li>Make derivative versions of this app</li>
        </ul>
        <p>Parts of this app offer an opportunity for users to post and exchange opinions and information in certain areas of the website. One a Day does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of One a Day, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, One a Day shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments in this app.</p>
        <p>One a Day reserves the right to monitor all Posts/Comments and to remove any Posts/Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
        <p>You warrant and represent that:</p>
        <ul>
        <li>You are entitled to post the Comments on our App and have all necessary licenses and consents to do so;</li>
        <li>The Posts/Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
        <li>The Posts/Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
        <li>The Posts/Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
        </ul>
        <p>You hereby grant One a Day a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
        <h1>Hyperlinking to our App</h1>
        <p>The following organisations may link to our App without prior written approval:</p>
        <ul>
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organisations;</li>
        <li>Online directory distributors may link to our App in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
        <li>System wide Accredited Businesses except soliciting non-profit organisations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</li>
        </ul>
        <p>These organisations may link to our home page, to publications or to other App information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party&rsquo;s site.</p>
        <p>We may consider and approve other link requests from the following types of organisations:</p>
        <ul>
        <li>commonly-known consumer and/or business information sources;</li>
        <li>dot.com community sites;</li>
        <li>associations or other groups representing charities;</li>
        <li>online directory distributors;</li>
        <li>internet portals;</li>
        <li>accounting, law and consulting firms; and</li>
        <li>educational institutions and trade associations.</li>
        </ul>
        <p>We will approve link requests from these organisations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organisation does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of One a Day; and (d) the link is in the context of general resource information.</p>
        <p>These organisations may link to our App so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party&rsquo;s site.</p>
        <p>If you are one of the organisations listed in paragraph 2 above and are interested in linking to our App, you must inform us by sending an email to One a Day. Please include your name, your organisation name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our App, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
        <p>Approved organisations may hyperlink to our App as follows:</p>
        <ul>
        <li>By use of our corporate name; or</li>
        <li>By use of the uniform resource locator being linked to; or</li>
        <li>By use of any other description of our App being linked to that makes sense within the context and format of content on the linking party&rsquo;s site.</li>
        </ul>
        <p>No use of One a Day's logo or other artwork will be allowed for linking absent a trademark licence agreement.</p>
        <h1>iFrames</h1>
        <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our App.</p>
        <h1>Content Liability</h1>
        <p>We shall not be held responsible for any content that appears on your App. You agree to protect and defend us against all claims that are rising on our App. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
        <h1>Security and Privacy</h1>
        <p>The One a Day app stores and processes personal data that you have provided to us, to provide my Service. It&rsquo;s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone&rsquo;s security features and it could mean that the One a Day app won&rsquo;t work properly or at all.</p>
        <p>For more information, please read <a href="/privacy">Privacy Policy</a>.</p>
        <h1>Reservation of Rights</h1>
        <p>We reserve the right to request that you remove all links or any particular link to our App. You approve to immediately remove all links to our App upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our App, you agree to be bound to and follow these linking terms and conditions.</p>
        <p>Removal of links from our App</p>
        <p>If you find any link on our App that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
        <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
        <h1>App changes</h1>
        <p>Klaudia Bronowicka is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you&rsquo;re paying for.&nbsp;</p>
        <p>With respect to Klaudia Bronowicka&rsquo;s responsibility for your use of the app, when you&rsquo;re using the app, it&rsquo;s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Klaudia Bronowicka accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>
        <p>At some point, we may wish to update the app. The app is currently available on Android and iOS &ndash; the requirements for the both systems(and for any additional systems we decide to extend the availability of the app to) may change, and you&rsquo;ll need to download the updates if you want to keep using the app. We do not promise that we will always update the app so that it is relevant to you and/or works with the Android &amp; iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licences granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>
        <h1>Network connection</h1>
        <p>You should be aware that there are certain things that Klaudia Bronowicka will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but Klaudia Bronowicka cannot take responsibility for the app not working at full functionality if you don&rsquo;t have access to Wi-Fi, and you don&rsquo;t have any of your data allowance left.</p>
        <p>If you&rsquo;re using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you&rsquo;re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you&rsquo;re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</p>
        <h1>Disclaimer</h1>
        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our App and the use of this website. Nothing in this disclaimer will:</p>
        <ul>
        <li>limit or exclude our or your liability for death or personal injury;</li>
        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>
        <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
        <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
        <h1>Changes to This Terms and Conditions</h1>
        <p>I may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Terms and Conditions on this page. App users will be notified of the changes by email.</p>
        <p>These terms and conditions are effective as of 2022-09-01</p>
        <h1>Contact Us</h1>
        <p>If you have any questions or suggestions about these Terms and Conditions, do not hesitate to contact us at <a href="mailto:hello@oneadayapp.com" target="_blank" rel="noopener">hello@oneadayapp.com</a>.</p>
    </section>
    </div>
  </Layout>
);

export default TermsPage;
